/**
 * credit for fuzzysearch: https://github.com/bevacqua/fuzzysearch/blob/master/index.js
 *
 * Returns true if needle matches haystack using a fuzzy-searching algorithm.
 * Note that this algorithm doesn't implement 'levenshtein distance',
 * but rather a simplified version where there's no approximation.
 * The method will return true only if each character in the needle can
 * be found in the haystack and occurs after the preceding matches.
 * @param {string} needle
 * @param {string} haystack
 */
export const fuzzysearch = (needle: string, haystack: string) => {
  const hlen = haystack.length
  const nlen = needle.length
  if (nlen > hlen) {
    return false
  }
  if (nlen === hlen) {
    return needle === haystack
  }
  outer: for (let i = 0, j = 0; i < nlen; i++) {
    const nch = needle.charCodeAt(i)
    while (j < hlen) {
      if (haystack.charCodeAt(j++) === nch) {
        continue outer
      }
    }
    return false
  }
  return true
}
