import React from 'react'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/pipeable'
import { navigate } from 'gatsby'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import Layout from '../components/layout'
import { useSpring, animated } from 'react-spring'
import { EndPoint } from '../components/nav/header-nav'
import { fuzzysearch } from '../util/fuzzy-search'
import { useProducts } from '../hooks/useProducts'

const StyledBackground = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23e8e5e5' fill-opacity='0.4'%3E%3Cpath d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
`

export const ParticipatingSchools = () => {
  const springOpacity = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  })

  const [searchTerm, setSearchTerm] = React.useState<O.Option<string>>(O.none)

  const { availableSchools } = useProducts()

  const schoolCollection = pipe(
    availableSchools,
    O.fold(
      () => null,
      schools =>
        O.isSome(searchTerm)
          ? schools.filter(s =>
              fuzzysearch(
                searchTerm.value.toLocaleLowerCase(),
                s.productName.toLocaleLowerCase()
              )
            )
          : schools
    )
  )

  return (
    <Layout seoTitle="Participating Schools">
      <animated.div style={springOpacity} className="bg-gray-50">
        <div className="p-4 bg-gradient-to-tr from-accent-300 via-yellow-400 to-orange-500">
          {/* <p className="text-2xl text-center leading-6 text-accent-100 font-semibold tracking-wide uppercase">
            Participating Schools
          </p>
          <p className="text-xl text-center leading-6 text-accent-200 mt-1">
            We&apos;re in community centers and over 23 OCPS schools in Orlando.
          </p> */}
          {/* <div className="mt-2 mb-6 flex justify-center">
            <div className="w-96">
              <label htmlFor="search-school" className="sr-only">
                Search
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <svg
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    >
                      <path d="M15.853 16.56A9.458 9.458 0 019.5 19C4.257 19 0 14.743 0 9.5S4.257 0 9.5 0 19 4.257 19 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zM9.5 1C14.191 1 18 4.809 18 9.5S14.191 18 9.5 18 1 14.191 1 9.5 4.809 1 9.5 1z" />
                    </svg>
                  </svg>
                </div>
                <input
                  id="search-school"
                  className="form-input block w-full pl-10 sm:text-sm sm:leading-5"
                  placeholder="Search for school.."
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchTerm(
                      e.target.value !== ''
                        ? O.some(e.target.value.trim())
                        : O.none
                    )
                  }
                />
              </div>
            </div>
          </div> */}
          {/* {pipe(
            O.fromNullable(schoolCollection),
            O.fold(
              () => null,
              schools => (
                <ul className="grid grid-cols-1 gap-8 sm:grid-cols-2 mb-6 px-4">
                  {schools.length > 0 ? (
                    schools.map(s => (
                      <motion.li
                        key={s._id}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow-md hover:shadow-lg"
                      >
                        <div className="flex-1 flex flex-col p-8">
                          <img
                            className="w-32 h-32 flex-shrink-0 mx-auto bg-black rounded-full object-cover"
                            src={
                              s?.imageUrl ??
                              'https://res.cloudinary.com/leportail-app/image/upload/v1601761785/Screen_Shot_2020-10-03_at_5.49.22_PM_hjypvz.png'
                            }
                            alt=""
                          />
                          <h3 className="mt-6 text-cool-gray-600 text-sm leading-5 font-medium">
                            {pipe(
                              s.productName,
                              x => x.split('-'),
                              xs => (
                                <>
                                  {' '}
                                  <span>{xs[0]}</span>-
                                  <strong className="text-yellow-400">
                                    {xs[1]}
                                  </strong>{' '}
                                </>
                              )
                            )}
                          </h3>
                          {pipe(
                            O.fromNullable(s?.weekDaysAvailable),
                            O.fold(
                              () => null,
                              days => (
                                <dl className="mt-1 flex-grow flex flex-col justify-between">
                                  <dt className="sr-only">
                                    Available days of the week
                                  </dt>
                                  <ul>
                                    {days.map((d, idx) => (
                                      <li
                                        key={d + idx}
                                        className="text-cool-gray-400 text-sm"
                                      >
                                        {d}
                                      </li>
                                    ))}
                                  </ul>
                                </dl>
                              )
                            )
                          )}
                        </div>
                        <div className="border-t rounded-b-md bg-cool-gray-200">
                          <div className="-mt-px flex">
                            <div className="w-0 flex-1 flex border-r rounded-b-md bg-cool-gray-200">
                              <div
                                onClick={() =>
                                  navigate(
                                    `${EndPoint.FIT_KIDZ_4_FUN_REGISTRATION}?plan=${s._id}&type=after-school`
                                  )
                                }
                                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-cool-gray-600 leading-5 font-medium border border-transparent rounded-bl-lg focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 cursor-pointer"
                              >
                                <svg
                                  className="w-6 h-6"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                >
                                  <path d="M9.963 8.261c-.566-.585-.536-1.503.047-2.07l5.948-5.768c.291-.281.664-.423 1.035-.423.376 0 .75.146 1.035.44l-8.065 7.821zm-9.778 14.696c-.123.118-.185.277-.185.436 0 .333.271.607.607.607.152 0 .305-.057.423-.171l.999-.972-.845-.872-.999.972zm8.44-11.234l-3.419 3.314c-1.837 1.781-2.774 3.507-3.64 5.916l1.509 1.559c2.434-.79 4.187-1.673 6.024-3.455l3.418-3.315-3.892-4.019zm9.97-10.212l-8.806 8.54 4.436 4.579 8.806-8.538c.645-.626.969-1.458.969-2.291 0-2.784-3.373-4.261-5.405-2.29z" />
                                </svg>
                                <span className="ml-3">Register Now</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </motion.li>
                    ))
                  ) : (
                    <div className="col-span-4">
                      <div className="flex justify-center text-accent-100 mb-80">
                        sorry, no schools available at the moment
                      </div>
                    </div>
                  )}
                </ul>
              )
            )
          )} */}
          <div className="col-span-4">
            <h1 className="flex justify-center text-white text-lg md:text-4xl">
              After School programs to return fall 2021
            </h1>
            <img
              className="rounded-md shadow-md"
              src="https://images.unsplash.com/photo-1611153394979-3a717e750c5f?ixid=MXwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=3038&q=80"
            />
          </div>
        </div>
      </animated.div>
    </Layout>
  )
}

export default ParticipatingSchools
